import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';

import Style from './index.less';

let router = createBrowserRouter([
  {
    path: "/",
    ErrorBoundary,
    Component: App,
    children: [
      {
        path: ":shortUrlId",
        Component: App,
        ErrorBoundary
      },
    ],
  },
]);


export function ErrorBoundary() {
  // let error = useRouteError() as Error;
  return (
    <div className={Style["error-page-container"]}>
      <img width={'60%'} src='https://l.talktan.net/assets/images/404.png' alt='page not found' />
    </div>
  );
}

const Index = () => {
  return <RouterProvider router={router} />;
}

export default Index;
