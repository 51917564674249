import Slider from "react-slick";
import cs from 'classnames';
import CallApp from "../utils/call";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Style from './App.less';

const sliderSettings = {
  dots: false,
  fade: true,
  infinite: true,
  centerMode: false,
  autoplay: true,
  speed: 260,
  slidesToShow: 1,
  slidesToScroll: 1,
  waitForAnimate: true,
  arrows: false,
};

const callAppOption = {
  scheme: {
    protocol: 'klcscheme',
  },
  intent: {
    package: 'com.talkten.ydy',
    scheme: 'klcscheme',
  },
  universal: {
    host: '',
    pathKey: '',
  },
  appstore: '',
  yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.talkten.ydy',
  fallback: 'https://ycc1.talktan.com/sys/QingPan_1.17_0704_1411_64.apk',
  timeout: 2000,
};

const App = () => {
  const callApp = () => {
    const lib = new CallApp(callAppOption);
    lib.open({
      path: 'com.talkten.ydy/push_click'
    });
  };

  return (
    <div className={Style['page-container']}>
      <div className={Style['slider-container']}>
        <Slider {...sliderSettings}>
          {
            Array(7).fill(null).map((_, index) => (
              <div key={`${Math.floor(Math.random() * 10e10).toString(16)}-${index}`} className={cs(Style['silder-item'], Style[`item${++index}`])}>
                <img width="90%" src="https://talktan.com/img/slogan.png" alt='心若有情，便可期盼，愿与君在此共鸣——情盼！' />
              </div>
            ))
          }
        </Slider>
      </div>
      <button className={Style['download']} onClick={callApp} />
    </div>
  );
}

export default App;
